import $ from 'jquery';

import { $Elements, Events, Classes, Elements } from '../global/constants';
import { ajax } from '../global/helpers';

$Elements.document.on(Events.page.enter, () => {

	// ---------- Newsletter ----------

	if(typeof newsletter_params !== 'undefined') {

		$('form.newsletter-form').each(function() {

			const form = $(this);
			const input = form.find('input[type=email]');
			const button = form.find('button[type=submit]');
			const tooltip = form.find('.invalid-tooltip');

			form.on('submit', async e => {
				e.preventDefault();
				e.stopImmediatePropagation();
				e.stopPropagation();

				input.removeClass('is-invalid');
				button.attr('disabled', true);
				Elements.LoadingBar.classList.add(Classes.loading);

				const formData = new FormData(form[0]);
				formData.append('action', newsletter_params.action);
				formData.append('nonce', newsletter_params.nonce);

				try {

					const response = await ajax({
						url: newsletter_params.url,
						type: 'POST',
						dataType: 'json',
						data: formData,
						processData: false,
						contentType: false,
					});

					form.empty();
					form.append('<div class="success-message"><span>' + response.data.message + '</span></div>')
				}
				catch(e) {

					if(e.data.error) {
						tooltip.text(e.data.error);
						input.addClass('is-invalid');
					}
				}

				button.attr('disabled', false);
				Elements.LoadingBar.classList.remove(Classes.loading);

				return false;
			});
		});
	}
});
