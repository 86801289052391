import $ from 'jquery';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { $Elements, Events, Classes, Elements } from './constants';
import { scrollTo } from './helpers';

$Elements.document.on(Events.page.enter, () => {

	// ---------- Menu ----------

	$('.menu-toggle, a[href="#menu-wrapper"]').click(function(e) {
		e.preventDefault();

		if($Elements.body.hasClass(Classes.menuOpen)) {
			$Elements.body.removeClass(Classes.menuOpen);
			enableBodyScroll(Elements.MenuWrapper);
		}
		else {
			$Elements.body.addClass(Classes.menuOpen);
			disableBodyScroll(Elements.MenuWrapper);
		}
	});

	$('.menu-overlay').click(function(e) {
		e.preventDefault();

		$('.menu-toggle').trigger('click');
	});

	$('.navbar-menu a, .menu-wrapper a').click(function(e) {

		const linkTarget = this.getAttribute('href');
		const sectionTarget = linkTarget.substring(linkTarget.lastIndexOf('#'), linkTarget.length);

		if(sectionTarget.substring(0, 1) === '#') {

			e.preventDefault();

			const section = $(sectionTarget);

			if(section.length) {

				window.history.replaceState(null, null, sectionTarget);

				$Elements.body.removeClass(Classes.menuOpen);
				enableBodyScroll(Elements.MenuWrapper);
				scrollTo(section);
			}
			else if(sectionTarget !== '#') {
				window.location = linkTarget;
			}
		}
	});

	$Elements.window.on('load', function() {

		const linkTarget = window.location.href;
		const sectionTarget = linkTarget.substring(linkTarget.lastIndexOf('#'), linkTarget.length);

		if(sectionTarget.substring(0, 1) === '#') {

			const section = $(sectionTarget);

			if(section.length) {
				$Elements.body.removeClass(Classes.menuOpen);
				enableBodyScroll(Elements.MenuWrapper);
				scrollTo(section, 0);
			}
		}
	});
});
