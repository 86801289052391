import $ from 'jquery';

import { wait } from '../global/helpers';
import { $Elements, Events, Classes, Elements, Durations, BarbaLinks } from '../global/constants';

// ---------- Handle In ----------

$Elements.document.ready(() => $Elements.document.trigger(Events.page.enter));

$Elements.window.on('load', transitionIn);

$Elements.window.on('pageshow', e => {
	if(e.originalEvent.persisted) {
		window.location.reload();
		transitionIn();
	}
});

// ---------- Hnadle Out ----------

$Elements.window.on('unload', () => null);

$(BarbaLinks).on('click', async e => {

	e.preventDefault();

	await transitionOut();

	if(e.which === 2 || e.metaKey || e.shiftKey || navigator.platform.toUpperCase().indexOf('WIN') !== -1 && e.ctrlKey) {
		window.open(e.currentTarget.href, '_blank');
	}
	else {
		window.location.href = e.currentTarget.href;
	}
});

// ---------- Transitions ----------

function transitionIn() {

	$Elements.document.trigger(Events.page.loaded);

	Elements.LoadingBar.classList.remove(Classes.loading);
	// Elements.LoadingOverlay.classList.remove(Classes.loading);
	// Elements.MainWrapper.classList.remove(Classes.loading, Classes.initialLoad);
}

async function transitionOut() {

	$Elements.document.trigger(Events.page.leave);

	Elements.LoadingBar.classList.add(Classes.loading);
	// Elements.LoadingOverlay.classList.add(Classes.loading);
	// Elements.MainWrapper.classList.add(Classes.loading);

	await wait(Durations.page);
}
